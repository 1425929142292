<template>
	<div class="animate__animated animate__fadeIn">
		<top-bar>
			<div class="top-box">
				<img :src="logoImg" class="logo">
				<div class="menus-box" :style="fontColor">
					<div class="menus" v-for="(m,mi) in menus" :style="active == m.path ? {fontWeight:600} : ''" :key="mi" @mouseover="changeOver(m)" :class="m.hover ? 'hover-bg' : '' " @mouseleave="mouseLeave(m)" @click="changePath(m)">
						{{m.name}}
						<div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
						<div class="se-me hover-bg" v-if="m.hover" >
							<div class="child" :style="fontColor" v-for="(c,ci) in m.child" :key="ci" @click.stop="changePath(c)">
								{{c.name}}
							</div>
						</div>
					</div>
					
				</div>
			</div>
		</top-bar>
		<div class="project-box">
			<div class="bg">
				<div class="title">项目案例</div>
				<div class="tb">Project case</div>
			</div>
		</div>
		<div class="t-box">
			<div class="c-box">
				Project Case
				<div class="c-ti">项目案例</div>
			</div>
		</div>
		<div class="r-box">
			<div class="fb">
				<img src="../static/home/proImg5.png" class="left-img">
				<div class="right-box">
					<div class="tt">固碳预拌混凝土国内首次应用 助力国家电网打造零碳变电站</div>
					<div class="cc">国家电网浙江电力公司在湖州安吉开发区新建的零碳变电站，首次在国内大规模商业化应用有碳封存属性的固碳预拌混凝土。经测算，在该项目中每使用1m³固碳预拌混凝土，将比传统产品减少20%以上的碳排放。此次应用不仅打造了在国网系统内二氧化碳矿化技术的“首台首套”，更是实现了CCUS（碳捕集、利用与封存）技术在国内预拌混凝土领域的突破。</div>
					<button class="btn" @click="goInfo('CaseInfo3')">
						查看详情
						<img src="../static/home/youjiantou.png" >
					</button>
				</div>
			</div>
		</div>
		<br/><br/>
		<div class="r-box">
			<div class="fb">
				<img src="../static/home/proImg3.png" class="left-img">
				<div class="right-box">
					<div class="tt">固碳混凝土国内首次应用　CCUS技术助推全国首个施工期内实现碳中和项目落地</div>
					<div class="cc">为实现建筑项目在施工期内达到碳中和，利用CCUS技术生产的固碳混凝土建材近期在我国香港地区进行使用，这也是首个在中国应用二氧化碳矿化混凝土建材的建筑项目。该款拥有固碳属性的混凝土将帮助香港有机资源回收中心二期工程（O·PARK2）项目在施工阶段大幅降低碳足迹。</div>
					<button class="btn" @click="goInfo('CaseInfo2')">
						查看详情
						<img src="../static/home/youjiantou.png" >
					</button>
				</div>
			</div>
		</div>
		<br/><br/>
		<div class="r-box">
			<div class="fb">
				<img src="../static/home/proImg2.png" class="left-img">
				<div class="right-box">
					<div class="tt">全球首个工业规模CO₂矿化养护混凝土工程</div>
					<div class="cc">示范工程在河南的混凝土企业内通过72 小时运行。项目实现了每年1万吨的CO₂温室气体封存， 并生产1亿块MU15 标准的轻质实心混凝土砖，是全球第一个工业规模CO₂ 矿化养护混凝土示范工程，全生命周期减排效果全球领先。 </div>
					<button class="btn" @click="goInfo('CaseInfo')">
						查看详情
						<img src="../static/home/youjiantou.png" >
					</button>
				</div>
			</div>
		</div>
		<br><br>

		<div style="width: 100%;margin-top: 259px;">
			<footer-bar path="Project"></footer-bar>
		</div>
	</div>
</template>

<script>
	import topBar from '@/components/header-bar.vue'
	import utils from '@/utils/common.js'
	import footerBar from '@/components/footer-bar.vue'
	export default{
		components:{
			topBar,
			footerBar
		},
		data(){
			return{
				name:'solution',
				menus:utils.menus,
				active:'solution',
				swiperIndex:0,
				hoverIndex:null
			}
		},
		computed:{
			logoImg(){
				// console.log(this.swiperIndex,'lll')
				if(this.swiperIndex == 0){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 1){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 2){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 3){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 4){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 5){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 6){
					return require('../static/home/logo2.png')
				}
				
			},
			fontColor(){
				if(this.swiperIndex == 0){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						color:'#333'
					}
				}
			},
			dtBg(){
				if(this.swiperIndex == 0){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						background:'#333'
					}
				}
			}
		},
		methods:{
			changeOver(m){
				// console.log(m)
				if(m.path == 'solution'){
					m.hover = true
					this.active = m.path
				}
			},
			mouseLeave(m){
				this.menus.forEach(item=>item.hover = false)
				this.active = this.name
			},
			changePath(item){
				if(item.path != this.name){
					this.$router.push({
						name:item.path
					})
				}
			},
			overInfo(i){
				this.hoverIndex = i
			},
			leaveInfo(i){
				this.hoverIndex = null
			},
			goInfo(i){
				this.$router.push({
					name:i
				})
			}			
		}
	}
</script>

<style lang="scss" scoped>
	.wrap {
	    width: 100%;
	    height: 100vh;
	    overflow: hidden;
	    // background: #eeeeee;
		margin: 0;
		padding: 0;
		
	}
		.active-h{
			font-weight: 600;
		}
	.top-box{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		// background: #007AFF;
		.logo{
			width: 42px;
			height: 47px;
			margin-left: 11.25rem;
		}
		.menus-box{
			height: 100%;
			display: flex;
			align-items: center;
			margin-right: 197px;
			position: relative;
		}
		.menus{
			height: 100%;
			width: 114px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 60px;
			font-size: 14px;
			font-weight: 400;
			
			cursor: pointer;
			box-sizing: border-box;
			position: relative;
			.dt-line{
				width: 20px;
				height: 4px;
				// background: #FFFFFF;
				position: absolute;
				bottom: 16px;
			}
			.se-me{
				position: absolute;
				width: 114px;
				height: 108px;
				bottom: -108px;
			}
			.child{
				width: 100%;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 400;
				// color: #FFFFFF;
			}
		}
	}
	
	.project-box{
		width: 100%;
		height: 400px;
		// background: rgba(0, 0, 0, 0.5);
		background-image: url(../static/project/pr.png);
		position: relative;
		background-size: 100% 100%;

		.bg{
			padding-top: 182px;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			.title{
				font-size: 48px;
				color: #FFFFFF;
				margin-left: 175px;
			}
			.tb{
				font-size: 28px;
				font-weight: 400;
				color: #CCCCCC;
				margin-top: 12px;
				margin-left: 175px;
			}
		}

	}
	
	.t-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
		margin-bottom: 80px;
		.c-box{
			width: 365px;
			height: 58px;
			font-size: 58px;
			font-weight: bold;
			color: #F7F7F7;
			position: relative;
			.c-ti{
				font-size: 32px;
				font-weight: 600;
				color: #1A1A1A;
				position: absolute;
				bottom: 0;
				left: 35%;
			}
		}
	}
	
	.r-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.fb{
			width: 1080px;
			height: 323px;
			background: #FFFFFF;
			box-shadow: 2px 2px 20px 7px rgba(0, 0, 0, 0.05);
			display: flex;
			
			justify-content: space-between;
			.left-img{
				width: 536px;
				height: 323px;
			}
			.right-box{
				width: 536px;
				height: 323px;
				padding: 40px 0 0 40px;
				// align-items: flex-start;
				// justify-content: flex-start;
				box-sizing: border-box;
				.tt{
					font-size: 24px;
					font-weight: 500;
					color: #1A1A1A;
				}
				.cc{
					width: 448px;
					height: 112px;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					margin-top: 24px;
				}
				.btn{
					width: 134px;
					height: 50px;
					background: #075AD3;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					font-weight: 500;
					border: 0;
					margin: 0;
					margin-left: auto;
					margin-right: 40px;
					cursor: pointer;
					color: #FFFFFF;
					img{
						width: 18px;
						height: 18px;
						margin-left: 6px;
					}
				}
			}
		}
	}
	.new-box{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 60px;
		.c-box{
			width: 1087px;
			display: flex;
			flex-wrap: wrap;
			// background: #000;
			.new{
				width: 342px;
				height: 375px;
				background: #FFFFFF;
				box-shadow: 2px 2px 13px 5px rgba(0, 0, 0, 0.04);
				margin-bottom: 28px;
				position: relative;
				.img{
					width: 342px;
					height: 202px;
				}
				.tr{
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					font-size: 18px;
					font-weight: 500;
					color: #1A1A1A;
					margin-top: 20px;
				}
				.cr{
					width: 100%;
					padding: 0 20px;
					box-sizing: border-box;
					font-size: 16px;
					font-weight: 400;
					color: #666666;
					margin-top: 20px;
				}
				.bg-info{
					background: rgba(0, 0, 0, 0.4);
					position: absolute;
					left: 0;
					right: 0;
					top:0;
					bottom: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 28px;
					font-weight: 500;
					color: #FFFFFF;
					padding-top: 180px;
					box-sizing: border-box;
				}
			}
		}
	}
</style>
