<template>
	<div>
		<div class="foot-box">
			<div class="line_box">
				<img src="../static/wlogo.png"  class="logo"/>
				<div class="foot-title-box" v-for="(item,index) in footMenus" :key="index">
					<div class="header-title"  @click.stop="goPage(item)">{{item.title}}</div>
					<div class="content-box" v-for="(c,ci) in item.child" :key="ci" @click.stop="goChildPage(c)">
						<img :src="c.icon" class="icon" v-if="c.icon"/>
						<span class="con">{{c.title}}</span>
					</div>
				</div>
				<img src="../static/home/aquery.png" class="qr"/>
			</div>
			<div style="height: 49px;"></div>
			<div class="foot-bottom">
				<div style="display: flex;align-items: center; justify-content: center;width: 100%;">
					
					©2021 Cleanco2 Co.,Ltd. All rights reserved.<a href="http://beian.miit.gov.cn/" target="_blank" style="font-size: 9px;color: #fff;">京ICP备2021023826号</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
		   path:{
			   type:String,
			   default:''
		   }
		},
		data(){
			return{
				footMenus:[{
					title:'关于我们',
					hasShow:true,
					child:[{
						title:'公司介绍',
						icon:'',
						path:'About'
					}]
				},{
					title:'解决方案',
					hasShow:true,
					child:[{
						title:'核心技术',
						icon:'',
						path:'Technology'
					},{
						title:'项目案例',
						icon:'',
						path:'Project'
					}]
				},{
					title:'最新动态',
					hasShow:false,
					path:'Dynamic'
					// child:[{
					// 	title:'企业新闻',
					// 	icon:''
					// }]
				},{
					title:'联系我们',
					hasShow:true,
					child:[{
						title:'Email：hi@cleanco2.cn ',
						icon:require('../static/home/email.png')
					},{
						title:'地址： 北京市朝阳区东三环北路38号好世界商业广场',
						icon:require('../static/home/address.png')
					}]
				}]
			}
		},
		methods:{
			goChildPage(e){
				// console.log(this.path,e.path)
				if(e.path && this.path != e.path){
					this.$router.push({
						name:e.path
					})
				}
			},
			goPage(item){
				if(!item.hasShow && item.path != this.path){
					this.$router.push({
						name:item.path
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.foot-box{
		width: 100%;
		// min-height: 365px;
		// flex: 1;
		height: 100%;
		background: #000C33;
		padding-top: 90px;
		position: relative;
		.line_box{
			width: 100%;
			display: flex;
			justify-content: space-around;
			.logo{
				width: 98px;
				height: 110px;
				margin-left: 180px;
			}
			.foot-title-box{
				margin-right: 70px;
				.header-title{
					font-size: 16px;
					font-weight: 500;
					cursor: pointer;
					color: #FFFFFF;
					margin-bottom: 24px;
				}
				.content-box{
					display: flex;
					align-items: flex-start;
					margin-bottom: 20px; 
					.icon{
						width: 20px;
						height: 20px;
						margin-right: 8px;
					}
					.con{
						max-width: 240px;
						font-size: 14px;
						font-weight: 400;
						color: #F2F2F2;
						cursor: pointer;
						
					}
				}
				
			}
			.qr{
				width: 119px;
				height: 119px;
				margin-right: 180px;
			}
		}
		.foot-bottom{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0px;
			height: 49px;
			background: #0C1949;
			z-index: 999;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			font-weight: 500;
			color: #FFFFFF;
			a:link{text-decoration: none;}
			a{text-decoration: none;}
		}
	}
</style>
